

function Loader ({ className, height, width, borderTopColor}) {

  if (!borderTopColor) {
    borderTopColor = '#a988e0';
  }

  if (!height) {
    height = 'h-12';
  }

  if (!width) {
    width = 'w-12';
  }

  return (
    <div className={className}>
    <div className="flex justify-center items-center h-full">
    {/* Loader Component */}
    <div className={`loader ease-linear rounded-full border-4 border-t-4 border-gray-200 ${height} ${width}`}></div>
    {/* Loader styles */}
    <style jsx="true">{`
      .loader {
        border-top-color: ${borderTopColor};
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </div>
  </div>
  );
};

export default Loader;
