import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, Outlet, useSearchParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Loader from './components/Loader';
import { getPatientMeetings } from './utils/firebase_api';
import axios from 'axios';
import moment from 'moment';
import { getDrFromDxOrDr } from './utils/utils';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RequireParams = () => {
  const {
    currentUser,
    patientDetails,
    patientData,
    isLoading,
    navigateToFollowup,
    navigateToSelectFollowupType,
  } = useAuth();

  const location = useLocation();
  let query = useQuery();
  const pathName = location.pathname;
  const isFollowup = pathName.includes('followup');
  const isFollowUpByState = pathName.includes('follow-up-by-state');
  const isNewPatient = pathName.includes('book-appointment');
  const isLogin = pathName.includes('login');
  const isAlreadyBooked = pathName.includes('already-booked');
  const isSelectFollowUpType = pathName.includes('select-follow-up-type');
  const isFromMeetNow = location?.state?.fromMeetNow;

  if (isLoading) {
    return (
      <Loader
        className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000 ${isLoading ? 'opacity-100' : 'opacity-0'}`}
      />
    );
  }

  if (isFromMeetNow) {
    return <Outlet />;
  }


  if (isAlreadyBooked && (query.get('dx') || query.get('dr'))) {
    query.delete('state');
    return <Navigate to={`/followup?${query.toString()}`} replace />;
  }

  if (navigateToSelectFollowupType && !isSelectFollowUpType) {
    query.delete('state');
    return <Navigate to={`/select-follow-up-type?${query.toString()}`} replace />;
  }

  if (navigateToFollowup && isNewPatient) {
    query.delete('state');
    return <Navigate to={`/followup?dr=${patientData.doctor_id || patientData.doctor}&${query.toString()}`} replace />;
  }

  if (!isLogin && isNewPatient && !query.get('state')) {
    return <Navigate to={`/select-state?${query.toString()}`} replace />;
  }

  if (isNewPatient && !query.get('type')) {
    query.set('type', '500961'); // new patient
  }

  if ((isFollowup || isFollowUpByState) && !query.get('type')) {
    query.set('type', '487240'); // follow-up
  }

  if (isFollowUpByState && !query.get('state')) {
    return <Navigate to={`/select-state?${query.toString()}`} replace />;
  }


  // if (isFollowup && query.get('dx')) {
  //   const dx = query.get('dx');
  //   console.log('dx', dx);
  //   const dr = await getDrFromDx(dx);

  //   console.log('dr', dr)




  //   if (dr) {
  //     sessionStorage.setItem('dr', dr);
  //     query.delete('dx');
  //   }
  // }



  return <Outlet />;
};

export default RequireParams;
