import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import stateCodeToName from '../utils/state_code_to_name.json';
import { useAuth } from '../AuthContext';
import Loader from './Loader';
import { clear } from '@testing-library/user-event/dist/clear';
import { getDrFromDxOrDr } from '../utils/utils';
import { get, set } from 'lodash';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SelectFollowUpType({ name, label, disabled, placeholder, onChange, value='', type, className}) {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const { currentUser, patientDetails, patientData, setNavigateToSelectFollowupType } = useAuth();

  console.log('currentUser', currentUser);
  // console.log('providerData', currentUser.providerData)
  console.log('patientDetails', patientDetails);
  console.log('patientData', patientData);

  const [state, setState] = useState('');
  const [dr, setDr] = useState(query.get('dr') || '');
  const [doctorName, setDoctorName] = useState('');

  const stateCodes = Object.keys(stateCodeToName);
  let stateOptions = stateCodes.map(stateCode => ({label: stateCodeToName[stateCode], value: stateCode}));
  stateOptions = [{label: 'Please select one...', value: ''}].concat(stateOptions);

  if (!className) {
    className = 'mt-1 block w-full border border-light-gray rounded-md shadow-md p-3 font-semibold';
  }

  const disabledClassNames = disabled ? 'bg-disabled' : '';

  className = `
    ${className}
    ${disabledClassNames}
  `;

  useEffect(() => {
    console.log('currentUser', currentUser);
    console.log('patientData', patientData);

    const getAndSetDoctorName = async (doctorId) => {
      const drData = await getDrFromDxOrDr({dr: doctorId});
      const firstName = drData.first_name;
      const lastName = drData.last_name;

      setDoctorName(`${firstName} ${lastName}`);
    };

    // Add a timeout where if the currentUser is not loaded after 5 seconds, then redirect to /login
    const timeout = setTimeout(() => {
      if (!currentUser) {
        const searchParams = new URLSearchParams(location.search);

        navigate(`/login?${searchParams.toString()}`, { state: { isSelectFollowUpType: true} });
      }
    }, 5000);

    if (currentUser?.uid && patientData) {
      clearTimeout(timeout);
      setState(patientData.state);
      if (!dr) {
        setDr(patientData.doctor_id);
      }
      getAndSetDoctorName(dr || patientData.doctor_id);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [currentUser, patientDetails, patientData]);

  useEffect(() => {
    if (doctorName) {
      setIsLoading(false);
    }
  }, [doctorName]);


  const onSelectMyProvider = (e) => {
    e.preventDefault();

    query.set('dr', dr);
    query.set('type', '487240'); // follow-up
    setNavigateToSelectFollowupType(false);

    navigate(`/followup?${query.toString()}`);
  };

  const onSelectAllProviders = (e) => {
    e.preventDefault();
    query.set('type', '487240'); // follow-up
    query.set('state', state);
    query.delete('dr');
    query.delete('dx');
    setNavigateToSelectFollowupType(false);

    navigate(`/follow-up-by-state?${query.toString()}`);
  };

  if (isLoading) {
    return (
      <Loader
        className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000 ${isLoading ? 'opacity-100' : 'opacity-0'}`}
      />
    );
  }

  return (
    <main className="flex-1 overflow-y-auto p-4 font-sans">
      <div className="h-full p-6 max-w-md mx-auto bg-white rounded-lg text-center mt-0">
        <div className="space-y-4">
          <div className="mt-3">
            <p className="text-3xl font-extrabold">Joyous Follow Up</p>
            <p className="text-sm font-bold text-gray mt-2">Follow Up Mental Wellbeing</p>
            <p className="text-lg text-black text-left mt-4">
              Your provider, <b>{doctorName}</b>, only has availability in 4 or more days from now.
              <br/>
              <br/>Please choose whether you would like to see your provider or another provider with more availability.

              </p>
          </div>

          <div className="flex space-x-4 mb-6">
            <button
              className="bg-deep-purple hover:bg-purple-600 text-white font-semibold py-3 px-6 rounded-md min-w-[184.27px] sm-max:min-w-[10px] sm-max:max-w-[150px] focus:outline-none"
              onClick={e => onSelectMyProvider(e)}
            >
              See My Provider
            </button><button
              className="bg-purple hover:bg-purple-600 text-white font-semibold py-3 px-6 rounded-md min-w-[184.27px] sm-max:min-w-[10px] sm-max:max-w-[150px] focus:outline-none"
              onClick={e => onSelectAllProviders(e)}
            >
              See All Providers
            </button>
          </div>

        </div>
      </div>
    </main>

  );
}
