import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import axios from 'axios';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getStateFullName,
  getMeetingTypeFullName,
  formatPhoneNumber,
  isValidEmail,
  isValidPhoneNumber,
  generateIntakeUrl,
  checkIfEmailExists,
  setSessionStorageWithExpiry,
  registerMixpanelEvent,
  convertMonthToNumber,
  getCookie,
  setCookie,
} from '../utils/utils';
import stateCodeToName from '../utils/state_code_to_name.json';
import TextInput from './TextInput';
import DateOfBirthInput from './DateOfBirthInput';
import AddressInput from './AddressInput';
import PhoneInput from './PhoneInput';
import DropdownInput from './DropdownInput';
import Alert from './Alert';
import { auth, db } from '../utils/firebase';
import { getPatientByUid, addAppointmentToPatient, updatePatientData } from '../utils/firebase_api';
import { ReactComponent as GlobeIcon } from '../assets/globe.svg';
import { ReactComponent as ProviderIcon} from '../assets/provider.svg';
import { ReactComponent as CalendarIcon } from '../assets/calendar.svg';
import { ReactComponent as ClockIcon } from '../assets/clock.svg';
import { ReactComponent as LeftArrowCircle } from '../assets/left_arrow_circle.svg';
import { ReactComponent as Loading } from '../assets/loading.svg';
import { useAuth } from '../AuthContext';
import { BookingContext } from '../ConditionalRoute';
import { set } from 'lodash';
import Modal from './Modal';
import Toast from './Toast';


// TODO: centralize this
const meetingTypeMap = {
  'new': 'New Patient Mental Wellbeing',
  'followup': 'Follow Up Mental Wellbeing',
  '500961': 'New Patient Mental Wellbeing',
  '487240': 'Follow Up Mental Wellbeing',
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function pad2(number) {
  if (_.isEmpty(number)) {
    return;
  }

  return (number < 10 ? '0' : '') + number
}

const getCookie2 = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export default function TimeslotForm() {
  const {
    bookingInProgress,
    setBookingInProgress,
    bookingConfirmed,
  } = useContext(BookingContext);

  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const { currentUser, patientDetails, patientData, patientIsNewNew, patientIsNew } = useAuth();
  const isFollowup = location.pathname.includes('followup');
  const isNewPatient = location.pathname.includes('book-appointment');
  const isFollowUpByState = location.pathname.includes('follow-up-by-state');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = e => {
    e.preventDefault();

    const selectedState = state;
    const stateInForm = formData?.state;

    if (isNewPatient && !_.isEmpty(stateInForm) && selectedState !== stateInForm && stateInForm !== undefined) {
      setIsModalOpen(true);
    } else {
      onSubmit(e);
    }
  };

  const closeModal = () => setIsModalOpen(false);

  const onModalCancel = e => {
    e.preventDefault();

    query.set('state', formData.state);

    navigate(`/book-appointment?${query.toString()}`, { replace: true, state: { formData } });
  };

  const [isEntering, setIsEntering] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const [existingFormData, setExistingFormData]  = useState(location.state?.formData || {});
  console.log('location.state', location.state)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailForm: '',
    DOBd: '',
    DOBm: '',
    DOBy: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    ...existingFormData,
    // meetingLocation: '',
  });
  console.log('existingFormData', existingFormData);
  console.log('formData', formData);
  const [fieldsToDisable, setFieldsToDisable] = useState([]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [redirectTime, setRedirectTime] = useState(4);
  const [startRedirectTimer, setStartRedirectTimer] = useState(false);

  useEffect(() => {
    setIsEntering(true);
  }, []);

  useEffect(() => {
    if (!bookingInProgress && bookingConfirmed) {
      createCheckoutSession();
    }
  }, [bookingInProgress, bookingConfirmed]);

  useEffect(() => {
    let timer;

    if (startRedirectTimer && redirectTime > 0) {
      timer = setInterval(() => {
        setRedirectTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (redirectTime === 0) {
      setStartRedirectTimer(false);
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [startRedirectTimer, redirectTime]);

  useEffect(() => {
    const maybeFillForm = async () => {
      if (currentUser) {
        const phoneNumber = formatPhoneNumber(currentUser.providerData[0].phoneNumber) || '';
        const uid = currentUser.uid;

        console.log('providerData', currentUser.providerData)
        // user.providerData[0] is trash
        console.log('data', patientData);

        let newDataToSet = {
            // ...formData,
            ...patientData?.firstName && { firstName: patientData?.firstName },
            ...patientData?.lastName && { lastName:  patientData?.lastName },
            ...phoneNumber && { phoneNumber},
            ...patientData?.emailForm && { emailForm: patientData?.emailForm },
            ...patientData?.DOBd && { DOBd: patientData?.DOBd?.toString() },
            ...patientData?.DOBm && { DOBm: patientData?.DOBm},
            ...patientData?.DOBy && { DOBy: patientData?.DOBy },
            ...patientData?.address1 && { address: patientData?.address1},
            ...patientData?.city && { city: patientData?.city},
            ...patientData?.state && { state: patientData?.state },
            ...patientData?.zipCode && {zipCode: patientData?.zipCode},
        };

        let dataToSet = {
          ...formData,
          ...newDataToSet,
        };

        console.log('dataToSet', dataToSet);

        const fieldsToDisable = Object.keys(newDataToSet).filter(fieldName => !!newDataToSet[fieldName]);
        console.log('fieldsToDisable', fieldsToDisable);
        setFieldsToDisable(fieldsToDisable);

        setFormData(dataToSet);

      } else {
        // User is signed out, clear the form fields
        // setFormData({});

      }
    }

    maybeFillForm();
  }, [currentUser, patientData, patientDetails]);

  useEffect(() => {
    if (!currentUser?.uid) {
      return;
    }

    registerMixpanelEvent({
      eventType: 'patientAuth',
      uid: currentUser.uid,
      data: {
        source: 'bookAppointment',
      }
    });

  }, [currentUser]);

  const {
    calenderId,
    organizer,
    organizerName,
    room,
    start,
    end,
    type,
    state,
    timezone,
  } = location.state?.timeslot;

  console.log('start', start)

  const stateFullName = getStateFullName(state?.toUpperCase());
  const meetingTypeFullName = getMeetingTypeFullName(type);
  const differenceInMinutes = moment(end).diff(moment(start), 'minutes');
  const stateCodes = Object.keys(stateCodeToName);
  const [errorTimer, setErrorTimer] = useState(null);

  const handleChange = e => {
    if (e.target.name.includes('DOB')) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name.includes('phone')) {
      setFormData({
        ...formData,
        [e.target.name]: `+1${e.target.value.replace(/\D/g, '')}`,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleEmailBlur = e =>{
    // setShowError(false);
    if (errorTimer) {
      clearTimeout(errorTimer)
    }
    const email = e.target.value.toLowerCase();

    const valid = isValidEmail(email);

    if (!valid) {
      setErrorMsg('Please enter a valid email address.');
      handleShowError();
    }
  };

  const handleShowError = () => {
    setShowError(true);
    const timer = setTimeout(() => {
      setShowError(false);
    }, 6000);


    setErrorTimer(timer);
  };

  const handleAddressChange = e => {
    if (e.target?.value || e.target?.value === '') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    } else {
      // bulk update from using google places api auto fill
      setFormData({
        ...formData,
        address: e.address,
        city: e.city,
        state: e.state,
        zipCode: e.zipCode,
      });
    }
  };


  const handleBack = e => {
    e.preventDefault();
    setIsExiting(true);
    setTimeout(() => {
      const searchParams = new URLSearchParams(location.search);
      let backPath = '';
      if (isFollowup) {
        backPath = `/followup?${searchParams.toString()}`;
      } else if (isFollowUpByState) {
        backPath = `/follow-up-by-state?${searchParams.toString()}`;
      } else {
        backPath = `/book-appointment?${searchParams.toString()}`;
      }

      navigate(backPath, { state: { formData } });
    }, 500);
  };

  const handleClearReschedule = () => {
    const locationState = location.state;

    delete locationState.reschedule;

    navigate(location.pathname, { replace: true, state: locationState});
  };

  const selectedTimeslotIsAvailable = async () => {
    const selectedStartDate = moment(start).format('YYYY-MM-DD');

    const endpoint = 'https://us-central1-joyous-web.cloudfunctions.net/v2/getAvailableTimeSlots';
    const params = {
      state: state || '',
      timezone,
      startDate: selectedStartDate,
      endDate: moment(end).add(1, 'day').format('YYYY-MM-DD'),
      dr: organizer || '',
      appointmentType: meetingTypeFullName,
      redirect: 'https:/google.com/'
    };


    const response = await axios.get(endpoint, { params });

    if (response?.data.slots) {
      const slots = response.data.slots;
      const slotsForSelectedDate = slots[selectedStartDate];

      const foundTimeslot = _.find(slotsForSelectedDate, (timeslot) => {
        return timeslot.start === start && timeslot.organizer === organizer;
      });

      return !!foundTimeslot;
    } else {
      return false;
    }
  };

  const onSubmit = async(e) => {
    e.preventDefault();
    closeModal();

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setFormDisabled(true);
    setShowError(false);
    setErrorMsg('');

    const isAuthenticated = !!auth.currentUser;

    if (!isAuthenticated) {
      handleNotAuthenticated(e);
      return;
    }

    const validEmail = isValidEmail(formData.emailForm);

    if (!validEmail) {
      setErrorMsg('Please enter a valid email address.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }

    const validPhoneNumber = isValidPhoneNumber(formData.phoneNumber);

    if (!validPhoneNumber) {
      setErrorMsg('Please enter a valid phone number.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }

    const anyFieldsEmpty = Object.values(formData).some(value => !value);

    if (anyFieldsEmpty) {
      console.log('emptyFields', formData);
      setErrorMsg('Please fill out all required fields.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }


    // Make email all lower case
    formData.emailForm = formData.emailForm.toLowerCase();
    setFormData({
      ...formData,
      emailForm: formData.emailForm.toLowerCase(),
    });

    const doesEmailExist = await checkIfEmailExists(formData.emailForm, auth.currentUser.uid, formData.phoneNumber);
    console.log('doesEmailExist', doesEmailExist)

    if (!doesEmailExist) {
      setErrorMsg('There was a problem checking your email. Please try again later.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }

    if (doesEmailExist.exists) {
      setErrorMsg('Please use a different email.');
      // setShowError(true);
      handleShowError();
      setIsLoading(false);
      setFormDisabled(false);
      return;
    }

    // UPDATE PATIENT DETAIILS HERE
    const uid = auth.currentUser.uid;
    const magicId = patientData?.magic_id;
    const clickedMeetNow = sessionStorage.getItem('clicked-meet-now');

    if (isNewPatient) {
      await updatePatientData(uid, {
        ...formData,
        ...clickedMeetNow && { meetNow: true},
        ...clickedMeetNow && { meetNowTs: new Date().getTime() },
        meeting: start,
        intakeUrl: generateIntakeUrl(formData, magicId),
      });
    }


    console.log('Form submitted:', formData);

    console.log(type, organizer, start, room)

    const timeslotIsAvailable = await selectedTimeslotIsAvailable();

    console.log('is available?', timeslotIsAvailable);

    if (!timeslotIsAvailable) {
      setIsLoading(false);
      setErrorMsg('This appointment has already been taken. Please select another time slot.');
      handleShowError();
      setFormDisabled(false);

      return;
    }

    // let result = await reserveMeeting(type, organizer, start, room);

    sessionStorage.setItem('formData', JSON.stringify(formData));
    setCookie('formData', JSON.stringify(formData), 5);

    let result = reserveMeeting(type, organizer, start, room);

    try {
      const postedRedis = await axios.post('https://us-central1-joyous-web.cloudfunctions.net/v2/getOrSetIsCurrentlyBooking', {
        uid: auth.currentUser.uid,
        start: JSON.stringify(start),
      });

      setBookingInProgress(true);
      return;
    } catch (e) {
      console.error('Error posting to redis:', e);
      setIsLoading(false);
      setErrorMsg('There was a problem booking your appointment. Please try again later.');
      // setShowError(true);
      handleShowError();
      setFormDisabled(false);


      return;
    }



    // console.log('result', result);

    // if (result?.data?.res === 404 || result?.status === 404) {
    //   console.log('already taken');
    //   setIsLoading(false);
    //   setErrorMsg('This appointment has already been taken. Please select another time slot.');
    //   // setShowError(true);
    //   handleShowError();
    //   setFormDisabled(false);
    // } else if (result?.data?.res === 200) {
    //   console.log('meeting booked');

    //   // setConfirmed(true);
    //   setStartRedirectTimer(true);

    //   await createCheckoutSession();
    // } else {
    //   // Handle timeout from server - 503
    //   // Check to see if there is a booked meeting in the future
    //   const futureMeetingTimes = await getFutureMeetingTimes(); // all epoch ms
    //   const mappedFutureMeetingsTimes = futureMeetingTimes.map(time => moment.tz(time, timezone).format('YYYY-MM-DDTHH:mm:ssZZ'));

    //   if (mappedFutureMeetingsTimes.includes(start)) {
    //     // setConfirmed(true);
    //     setStartRedirectTimer(true);

    //     await createCheckoutSession();
    //   } else {

    //     setIsLoading(false);
    //     setErrorMsg('There was a problem booking your appointment. Please try again later.');
    //     // setShowError(true);
    //     handleShowError();
    //     setFormDisabled(false);
    //   }
    // }

    // return;
  };

  const getFutureMeetingTimes = async () => {
    const uid = auth.currentUser.uid;
    try {
      const result = await axios.get(`https://us-central1-joyous-web.cloudfunctions.net/v2/getFutureMeetings?uid=${uid}`);

      return result.data.futureMeetingsStartTime;
    } catch (e) {
      console.error('Error getting future meetings:', e);
    }
  };

  const reserveMeeting = async (profile, doctor, meeting, room) => {
    const uid = auth.currentUser.uid;
    const params = {
      uid,
      profile,
      doctor,
      meeting,
      exam_room: room,
    };

    if (query.get('responder_uuid')) {
      params.responder_uuid = query.get('responder_uuid');
    }

    console.log('params', params);
    try {
      let res = await axios.post('https://drchrone-api.herokuapp.com/bookMeeting', params);
      return res;
    } catch (e) {
      console.error('Error booking meeting:', e);

      // handle outside
      // setIsLoading(false);
      // setErrorMsg('There was a problem booking your appointment. Please try again later.');
      // // setShowError(true);
      // handleShowError();
      // return;
    }
  };

  const createCheckoutSession = async () => {
    try {
      let providerName = organizerName;
      let magicId = patientData?.magic_id;
      const uid = auth.currentUser.uid;

      setStartRedirectTimer(true);

      console.log('ok creating checkout session!!!!!', {
        start,
        end,
        doctor: organizer,
        doctorName: providerName,
        meetingType: meetingTypeMap[type],
      });

      let result = await addAppointmentToPatient(uid, {
        start,
        end,
        doctor: organizer,
        doctorName: providerName,
        meetingType: meetingTypeMap[type],
      });

      const _fbc = getCookie2('_fbc');
      const _fbp = getCookie2('_fbp');

      if (_fbc || _fbp) {
        let result2 = await updatePatientData(uid, {
          ..._fbc && { _fbc },
          ..._fbp && { _fbp },
        });
      }

      const isSameDay = moment(start).tz(timezone).isSame(moment.tz(timezone).startOf('day'), 'day');

      console.log('create checkout session', result)
      const sessionFormData = JSON.parse(sessionStorage.getItem('formData'));
      const cookieFormData = JSON.parse(getCookie('formData'));

      const formDataToUse = sessionFormData || cookieFormData || formData;

      if ((patientIsNewNew || patientIsNew) && (sessionStorage.getItem('clicked-meet-now') || isSameDay)) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          em: formDataToUse?.emailForm?.toLowerCase(),
          ph: '1' + formDataToUse?.phoneNumber?.replace(/\D/g, ''),
          fn: formDataToUse?.firstName?.toLowerCase(),
          ln: formDataToUse?.lastName?.toLowerCase(),
          db: `${formDataToUse?.DOBy}${convertMonthToNumber(formDataToUse?.DOBm)}${pad2(formDataToUse?.DOBd)}`,
          ct: formDataToUse?.city?.toLowerCase().replace(/[^a-z]/g, ''),
          st: formDataToUse?.state?.toLowerCase(),
          zp: formDataToUse?.zipCode,
          country: 'us',
          uid,
          app_version: process.env.REACT_APP_VERSION,
        });

        //window.gtag('event', 'purchase',{currency: "USD",value:150.00});
        // fbq('track', 'Purchase', {currency: "USD", value: 150.00});
        //window.gtag_report_conversion();

        // window.rdt('track', 'Purchase', {
        //   "currency": "USD",
        //   "itemCount": 1,
        //   "value": 150,
        //   "products": [{
        //       "id": "joyous",
        //       "name": "joyous new meeting",
        //       "category": "booking"
        //     },
        //     // additional products can be added here
        //   ]
        // });

        window.gtag('event', 'conversion', {
          'send_to': 'AW-10950760124/7Zb9CNmnleYDELyt3eUo',
          'transaction_id': ''
        });
      } else if (isNewPatient) { // if in book-appointment
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          em: formDataToUse?.emailForm?.toLowerCase(),
          ph: '1' + formDataToUse?.phoneNumber?.replace(/\D/g, ''),
          fn: formDataToUse?.firstName?.toLowerCase(),
          ln: formDataToUse?.lastName?.toLowerCase(),
          db: `${formDataToUse?.DOBy}${convertMonthToNumber(formDataToUse?.DOBm)}${pad2(formDataToUse?.DOBd)}`,
          ct: formDataToUse?.city?.toLowerCase().replace(/[^a-z]/g, ''),
          st: formDataToUse?.state?.toLowerCase(),
          zp: formDataToUse?.zipCode,
          country: 'us',
          uid,
          app_version: process.env.REACT_APP_VERSION,
        });

        window.gtag('event', 'Schedule');
      }

      registerMixpanelEvent({
        eventType: 'bookAppointment',
        uid,
      });

      const isFollowup = meetingTypeMap[type].includes('followup');
      const isIntakeComplete = patientData?.intake_complete;

      const patientIntakeUrl = generateIntakeUrl(formDataToUse, magicId);

      // if (magicId) {
      //   window.gtag('event', 'book_magic', {
      //     id: magicId,
      //   });
      // }

      setSessionStorageWithExpiry('rescheduling' ,true,  5000);

      if (isFollowup || isFollowUpByState || isIntakeComplete) {
        setTimeout(() => {
          window.location = `https://n8n.joyous.team/webhook/new-appointment-redirect?${magicId ? `id=${magicId}` : ''}`;
        }, 4000);


      } else { // new meeting
        setTimeout(() => {
          if (magicId) {
            window.location = `https://joyous.team/intake?id=${magicId}`;
          } else {
            window.location= patientIntakeUrl;
          }
        }, 4000);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setIsLoading(false);
    }
  };

  const handlePhoneFocus = e => {
    if (!auth.currentUser) {
      handleNotAuthenticated(e);
    }
  };

  const handleNotAuthenticated = e => {
    e.preventDefault(); // Prevent the default focus action
    sessionStorage.setItem('formData', JSON.stringify(formData));

    const searchParams = new URLSearchParams(location.search);
    const isFollowUpByState = location.pathname.includes('follow-up-by-state');
    // If not authenticated, redirect to the login page// If not authenticated, redirect to the login page

    console.log('isFollowUpByState', isFollowUpByState);


    navigate(`/login?${searchParams.toString()}`, {
      state: {
        timeslot: location.state?.timeslot,
        formData,
        ...isFollowUpByState && { isFollowUpByState: true }
      }
    });
  };

  return (
    <div
      className={`font-sans top-0 left-0 w-full h-full transition-opacity duration-300 ease-in-out ${
        isEntering && !isExiting ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >

      <div className="flex items-center justify-center p-4 sm-max:justify-start sm-max:items-stretch sm-max:p-0">
      <div
        className="bg-white p-14 pt-2 sm-max:p-8 pt-10 rounded-lg sm-max:shadow-none sm-max:w-full sm-max:h-full sm-max:overflow-y-auto w-full mt-28 sm-max:mt-0 shadow-[]"
        style={{
          maxWidth: '900px',
        }}
      >
        <div className="space-y-4">
          <div className="relative p-4">
          <Toast message={errorMsg} isVisible={showError} setIsVisible={setShowError} onClose={() => setShowError(false)} />
          </div>
          <div className="text-3xl text-center font-extrabold mb-4">{stateFullName ? `Joyous ${stateFullName}` : organizerName}</div>

          {bookingConfirmed &&
            <div className="text-center text-5xl font-extrabold text-blue-500">
              Confirmed
            </div>
          }

          <div className={!bookingConfirmed ? "mb-4 flex items-center" : "mb-4 flex items-center justify-center"}>
            {!bookingConfirmed && (
              <div className="flex items-center text-blue-900 mb-2">
                <button onClick={e => handleBack(e)} className="relative top-[-47px]">
                  <LeftArrowCircle className="w-8 h-8 mr-3 font-bold text-gray"/>
                </button>
              </div>
            )}
            <div className="meeting-summary mt-2">
              <div className="flex items-center">
                <p className="text-2xl font-bold">{meetingTypeFullName}</p>
              </div>
              <div className="flex items-center">
                <ProviderIcon className="w-4 h-4 mr-2" />
                <p className="text-lg font-bold">{organizerName}</p>
              </div>
              <div className="flex items-center">
                <CalendarIcon className="w-4 h-4 mr-2" />
                <p className="text-lg font-bold">{moment(start).tz(timezone).format('dddd, MMMM D, YYYY')}</p>
              </div>
              <div className="flex items-center">
                <ClockIcon className="w-4 h-4 mr-2" />
                <p className="text-lg font-bold">{moment(start).tz(timezone).format('hh:mm A')} - {moment(end).tz(timezone).format('hh:mm A')} ({differenceInMinutes} min)</p>
              </div>
              <div className="flex items-center">
                <GlobeIcon className="w-4 h-4 mr-2" />
                <p className="text-lg font-bold">{timezone.replace('_', ' ')}</p>
              </div>
            </div>
          </div>

          {!bookingConfirmed &&(
              <form className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <TextInput
                  name="firstName"
                  type="text"
                  label="Legal first name*"
                  placeholder="First name"
                  value={formData.firstName}
                  onChange={handleChange}
                  disabled={fieldsToDisable.includes('firstName') || formDisabled}
                />
                <TextInput
                  name="lastName"
                  type="text"
                  label="Legal last name*"
                  placeholder="Last name"
                  value={formData.lastName}
                  onChange={handleChange}
                  disabled={fieldsToDisable.includes('lastName') || formDisabled}
                />
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <TextInput
                  name="emailForm"
                  type="text"
                  label="Email*"
                  placeholder="Email"
                  value={formData.emailForm}
                  onChange={handleChange}
                  onBlur={handleEmailBlur}
                  disabled={fieldsToDisable.includes('emailForm') || formDisabled}
                />

                <PhoneInput
                  name="phoneNumber"
                  label="Cellphone*"
                  onChange={handleChange}
                  value={formData.phoneNumber}
                  className="flex-1 block w-full rounded-none" placeholder="Cellphone"
                  onFocus={handlePhoneFocus}
                  disabled={fieldsToDisable.includes('phoneNumber') || formDisabled}
                />

              </div>

              <div>
                <DateOfBirthInput
                  name="DOB"
                  label="Date of birth*"
                  onChange={handleChange}
                  value={{DOBd: formData.DOBd, DOBm: formData.DOBm, DOBy: formData.DOBy}}
                  disabled={fieldsToDisable.includes('DOBd') || formDisabled}
                />
              </div>

              <div>
                <AddressInput
                  name="addressData"
                  onChange={handleAddressChange}
                  value={
                    {
                      address: formData.address,
                      city: formData.city,
                      state: formData.state,
                      zipCode: formData.zipCode,
                    }
                  }
                  disabled={fieldsToDisable.includes('address') || formDisabled}
                />
              </div>


              {/* <DropdownInput
                name="meetingLocation"
                label="Location during meeting"
                options={stateOptions}
                onChange={handleChange}
                tooltipText={"Please select your physical location (the state) you will be in when the meeting takes place."}
              /> */}

              <div>

                {/* {showError && (
                  <Alert
                    text={errorMsg}
                  />
                )} */}

                <button
                  type="submit"
                  className={`w-full bg-purple text-white text-xl font-black p-4 rounded-md mt-6 flex items-center justify-center ${
                    isLoading ? 'bg-purple cursor-not-allowed' : 'hover:bg-purple'
                  }`}
                  disabled={isLoading}
                  onClick={e => openModal(e)}
                >
                  {isLoading ? (
                    <Loading/>
                  ) : (
                    'Schedule appointment'
                  )}
                </button>
              </div>
            </form>
          )}

          {bookingConfirmed && (
            <div>
              <div className="flex text-center justify-center mx-auto max-w-96 mt-6 font-extrabold text-blue-500 text-2xl">
                The invitation has been sent to your email address
              </div>


              <div className="mt-8 max-w-96 bg-blue-50 text-center p-4 rounded-md justify-center mx-auto">
                <p className="font-extrabold text-gray-700 text-2xl">
                  You will be redirected to answer few questions needed for your Dr. appointment
                </p>
                <p className="text-2xl font-extrabold mt-2">{redirectTime}...</p>
              </div>
            </div>

          )}
        </div>
      </div>
    </div>


    <Modal
      isOpen={isModalOpen}
      message={`You have selected **${stateFullName}** for your appointment, but your address is listed as **${getStateFullName(formData.state)}**. To ensure your appointment is booked correctly, please choose the state where you will be during the meeting`}
      title="Different State"
      proceedText={`I will be in ${stateFullName}`}
      cancelText={`I will be in ${getStateFullName(formData.state)}`}
      onCancel={onModalCancel}
      onClose={closeModal}
      onProceed={e => onSubmit(e)}
    />

{/* <Toast message={'test'} show={showError} onClose={() => setShowError(false)} /> */}


  </div>
  );
}
