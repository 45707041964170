import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireTimeslot = ({ children }) => {
  const location = useLocation();

  if (!location.state?.timeslot) {
    const searchParams = new URLSearchParams(location.search);
    const isNewPatient = location.pathname.includes('book-appointment');
    const isFollowup = location.pathname.includes('followup');
    const isFollowUpByState = location.pathname.includes('follow-up-by-state');

    const path = isNewPatient ? 'book-appointment' : isFollowup ? 'followup' : isFollowUpByState ? 'follow-up-by-state' : '';

    return <Navigate to={`/${path}?${searchParams.toString()}`} replace />;
  }


  return children;
};

export default RequireTimeslot;
